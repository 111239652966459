import React from "react";
import { isEmpty } from "lodash";
import ContentSection from "../../../common/ContentSection";
import useStaticPageContent from "../../../../../hooks/useStaticPageContent";
import Page404 from "../../Page404";
import Loader from "../../../common/Loader";
import withTranslations from "../../../../../hocs/withTranslations";
import SeoMetaData from "../../../common/SeoMetaData/modules/default";

function GenericStaticPage({ match: { params }, locale }) {
  const { response, loading, error, seoDataResponse } = useStaticPageContent({
    slug: params.slug,
    locale
  });

  if (error) {
    return <Page404 />;
  }

  return (
    <ContentSection>
      {loading ? <Loader /> : null}
      {!!seoDataResponse && !isEmpty(seoDataResponse) ? (
        <SeoMetaData {...seoDataResponse} />
      ) : null}
      {response?.content ? (
        <>
          {seoDataResponse?.h1_title ? (
            <h1>{seoDataResponse.h1_title}</h1>
          ) : null}
          <div dangerouslySetInnerHTML={{ __html: response.content }} />
        </>
      ) : null}
    </ContentSection>
  );
}

export default withTranslations(GenericStaticPage);

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setGenericStaticPageTitle } from "../redux/modules/genericStaticPageTitle";
import useGetRequest from "./useGetRequest";

interface UseStaticPageContentProps {
  slug: String;
  locale: String;
}

function useStaticPageContent({ slug, locale }: UseStaticPageContentProps) {
  const urlPath = "app.staticPages.bySlug";
  const seoDataUrlPath = "app.staticSeoData.retrieve";
  const dispatch = useDispatch();

  const { response, loading, error } = useGetRequest({
    urlPath,
    variables: {
      slug
    },
    locale
  });

  const { response: seoDataResponse, error: seoDataError } = useGetRequest({
    urlPath: seoDataUrlPath,
    variables: {
      slug
    },
    locale
  });

  useEffect(() => {
    if (seoDataResponse?.meta_title) {
      dispatch(setGenericStaticPageTitle(seoDataResponse.meta_title));
    }

    return () => {
      dispatch(setGenericStaticPageTitle(null));
    };
  }, [response]);

  return { response, loading, error, seoDataResponse };
}

export default useStaticPageContent;

import * as WebBrowser from "expo-web-browser";
import { WebBrowserOpenOptions } from "expo-web-browser";
import { Actions } from "react-native-router-flux";
import Url from "url-parse";
import { hideAllModals } from '../../../lib/react-redux-modal-provider';

const useInternalWebBrowserForHosts = [
  "l.squaber.com",
  "blog.squaber.com",
  "edukacja.squaber.com"
];

const openBrowserAsync = (
  url: string,
  browserParams?: WebBrowserOpenOptions
) => {
  const { host } = new Url(url);

  if (useInternalWebBrowserForHosts.includes(host)) {
    hideAllModals()
    return Actions.internalWebBrowser({
      baseUrl: url
    });
  } else {
    return WebBrowser.openBrowserAsync(url, {
      createTask: false,
      ...(browserParams ?? {})
    });
  }
};

export default openBrowserAsync;

import classNames from "classnames";
import React from "react";

import connect from "../../../../../../../i18n/connect";
import useIsDesktop from "../../../../hooks/useIsDesktop";
import CountdownTimer from "../countdownTimer";

const PromotionPlanBanner = ({ translate }) => {
  const isDesktop = useIsDesktop();

  return (
    <div
      className={classNames(
        "rounded-1xl flex bg-blue gap-6 max-w-7xl mx-auto",
        {
          "px-12 py-3 mb-11 justify-between items-center": isDesktop,
          "pt-4 px-5 flex-col items-start mb-19 pb-6": !isDesktop
        }
      )}
    >
      <div>
        <strong
          className={classNames("block text-yellow", {
            "mb-1.5 text-2xl": isDesktop,
            "mb-4 text-xl": !isDesktop
          })}
          dangerouslySetInnerHTML={{
            __html: translate("lp_promo_section-3_banner_title")
          }}
        />
        <p
          className={classNames("mb-0 opacity-85 text-white", {
            "text-lg": isDesktop,
            "text-base": !isDesktop
          })}
          dangerouslySetInnerHTML={{
            __html: translate("lp_promo_section-3_banner_paragraph")
          }}
        />
      </div>
      <CountdownTimer
        targetTime={Number(translate("lp_promo_time"))}
        size={isDesktop ? "big" : "default"}
      />
    </div>
  );
};

export default connect()(PromotionPlanBanner);

import { createAction, handleActions } from "redux-actions";

export const SET_GENERIC_STATIC_PAGE_TITLE =
  "app/genericStaticPageTitle/SET_GENERIC_STATIC_PAGE_TITLE";

export const setGenericStaticPageTitle = createAction(
  SET_GENERIC_STATIC_PAGE_TITLE,
  (title: string) => ({ title })
);

const initialState = {
  title: null
};

export default handleActions(
  {
    [SET_GENERIC_STATIC_PAGE_TITLE]: (state, { payload }) => ({
      ...state,
      title: payload.title
    })
  },
  initialState
);

import SquaberIntervalMap from "./SquaberIntervalMap";

class SquaberTVDrawing {
  static _redrawShapes = function(tvWidget, shapeData) {
    const tvChart = tvWidget.chart();

    const result = [];

    for (let shape of _.values(shapeData.shapes)) {
      if (shape.entityId) {
        tvChart.removeEntity(shape.entityId, { disableUndo: true });
        shape.entityId = false;
      }

      if (
        !shapeData.preventDraw &&
        shapeData.actualIntervalTypeId === shape.intervalTypeId
      ) {
        shape.entityId = tvChart.createMultipointShape(
          shape.points,
          shape.options
        );

        result.push(shape.entityId);
      } else {
        result.push(undefined);
      }
    }

    return result;
  };

  static _manageShapesRedraw = function(tvWidget, shapeData) {
    const tvChart = tvWidget.chart();

    tvChart.onDataLoaded().subscribe(null, () => {
      SquaberTVDrawing._redrawShapes(tvWidget, shapeData);
    });

    return tvChart.onIntervalChanged().subscribe(null, interval => {
      shapeData.actualIntervalTypeId = SquaberIntervalMap[interval];
    });
  };
}

export default SquaberTVDrawing;

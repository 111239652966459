import React from "react";
import { Helmet } from "react-helmet";

interface SeoMetaDataProps {
  keywords?: String;
  meta_description?: String;
  meta_title?: String;
}

function SeoMetaData({
  keywords,
  meta_description,
  meta_title
}: SeoMetaDataProps) {
  return (
    <Helmet>
      {meta_title ? <title>{meta_title}</title> : null}
      {keywords ? (
        <meta name="keywords" key="keywords" content={keywords} />
      ) : null}
      {meta_description ? (
        <meta name="description" key="description" content={meta_description} />
      ) : null}
    </Helmet>
  );
}

export default SeoMetaData;

import HeaderContent from "../pages/Homepage/components/headerContent";
import LandingTypography from "../pages/Homepage/components/landingTypography";
import PremiumPlanBoxContent from "../pages/Homepage/components/premiumPlanBoxContent";
import HeaderContentPromotion from "../pages/Homepage/components/promotion/headerContentPromotion";
import PromotionPlanBanner from "../pages/Homepage/components/promotion/promotionPlanBanner";
import PromotionPremiumPlanBoxContent from "../pages/Homepage/components/promotion/promotionPremiumPlanBoxContent";
import PromotionToggleSelectPlan from "../pages/Homepage/components/promotion/promotionToggleSelectPlan";
import ToggleSelectPlan from "../pages/Homepage/components/toggleSelectPlan";
import HeaderImageDesktopDefault from "../pages/Homepage/assets/macbook_pro_1.png";

const isTimePromotion = (startTime, endTime) => {
  const now = new Date().getTime();

  return startTime && endTime && startTime <= now && now <= endTime;
};
const useTimedChanges = ({ translate }) => {
  const promotion = [
    Number(translate("lp_promo_start-time")),
    Number(translate("lp_promo_time"))
  ];

  const isPromotion = isTimePromotion(promotion[0], promotion[1]);

  if (isPromotion) {
    return {
      LandingTypography: LandingTypography,
      PlanBanner: PromotionPlanBanner,
      HeaderContent: HeaderContentPromotion,
      ToggleSelectPlan: PromotionToggleSelectPlan,
      TopBar: undefined,
      PremiumPlanBoxContent: PromotionPremiumPlanBoxContent,
      HeaderImageDesktop: HeaderImageDesktopDefault
    };
  }

  return {
    LandingTypography: LandingTypography,
    PlanBanner: undefined,
    HeaderContent: HeaderContent,
    ToggleSelectPlan: ToggleSelectPlan,
    TopBar: undefined,
    PremiumPlanBoxContent: PremiumPlanBoxContent,
    HeaderImageDesktop: HeaderImageDesktopDefault
  };
};

export default useTimedChanges;

export default {
  app: {
    translations: "translations/",
    menu: "menu/menu-item/",
    auth: {
      login: "auth/login/",
      register: "auth/register/",
      socialLogin: "auth/social_login/",
      socialRegister: "auth/social_register/",
      socialJoin: "auth/social_join/",
      facebook: "auth/facebook/",
      google: "auth/google/",
      linkedin: "auth/linkedin/",
      facebook_native: "auth/facebook/?is_native=true",
      google_native: "auth/google/?is_native=true",
      linkedin_native: "auth/linkedin/?is_native=true",
      googleV2Login: "auth/google/v2/login/",
      googleV2Register: "auth/google/v2/register/",
      user: "auth/me/",
      resetPassword: "auth/reset_password/",
      setPassword: "auth/set_password/",
      sendValidationEmail: "auth/send_validation_email/",
      setLocale: "auth/language/:locale/",
      editUserData: "auth/edit/",
      markets: "auth/user_preference/enabled_markets/",
      loginToken: "auth/get_login_token/",
      userMessages: "auth/user-messages/",
      skipUserMessage: "auth/user-messages/:messageId/skip",
      acceptUserMessage: "auth/user-messages/:messageId/accept",
      delete: "auth/delete/",
      unsubscribe: {
        electronicCommunication:
          "auth/disable_flag/electronic_communication/:userId/:hash",
        marketingEmail: "auth/disable_flag/marketing_emails/:userId/:hash"
      }
    },
    notification: {
      browser: "notifications/notifications_browser/:lastId",
      read_all: "notifications/read_all_notifications/",
      read: "notifications/read_notification/:id",
      unsubscribe: {
        notificationType:
          "notifications/subscription_status/:notificationType/:notificationChannel/:userId/:hash?status=remove"
      }
    },
    pushNotifications: {
      ios: "notifications/apn_status",
      android: "notifications/gcm_status"
    },
    stock: {
      search: "stock/search/:phrase",
      paginatedSearch: "stock/paginated-search/:phrase?limit=:limit",
      fastSearch: "stock/fast-search/:phrase?limit=:limit",
      item: "stock/get/:id",
      list: "stock/list/",
      analysisCategories: "stock/stock-analysis-categories/"
    },
    stocks: {
      follow: "squaber/follow/",
      unfollow: "squaber/follow/:stockId",
      bulkFollowUnfollow: "squaber/alert-many",
      toggleAlert: "squaber/alert/:stockId",
      singleStock: "squaber/:marketId/:stockId/?optimized=6",
      recommended: "squaber/has-recommended/",
      recommendations:
        "financial_report/recommendations/:stockId/:count/:start",
      mediaMonitor: "media_monitor/:market/:ticker/?limit=:count&offset=:start",
      mediaMonitorForChart:
        "media_monitor/:market/:ticker/?ts_start=:from&ts_stop=:to",
      singleMediaMonitor: "media_monitor/:mediaMonitorId",
      chartData:
        "squaber/chart_data/:stockId/:interval/?ts_start=:from&ts_stop=:to",
      chartDataCountBack: "squaber/chart_data/:stockId/:interval/?count=:count",
      lastMinMaxPoints:
        "squaber/chart-data/last-min-max-points/:stockId/?interval_type_id=:interval&ts_start=:from&ts_stop=:to",
      lastMinMaxPointsCount:
        "squaber/chart-data/last-min-max-points/:stockId/?interval_type_id=:interval&count=:count",
      currentLastMinMaxPoints:
        "squaber/chart-data/last-min-max-points/:stockId/?interval_type_id=:interval&current_only=true",
      insiderTransactionsMarkers:
        "squaber/chart-data/insiders-transactions/:stockId/?ts_start=:from&ts_stop=:to",
      insiderTransactionsMarkersCount:
        "squaber/chart-data/insiders-transactions/:stockId/?count=:count",
      simpleChartData: "squaber/chart_data/:stockId/7/?count=:count",
      manageFigures: "figures/manage/:stockId/:interval/?for_trading_view=1",
      userChartData: "squaber/trading_view_chart/:stockId/",
      transactionSignal:
        "signal/transaction-signal/:stockId/:strategyId/?limit=:limit&offset=:offset",
      searchAutocomplete:
        "stock/search/:query?exclude_index=true&currency=PLN&markets=gpw,nc",
      searchAutocompleteWithIndex: "stock/search/:query",
      searchAutocompletePortfolio:
        "stock/search/:query?exclude_index=true&currency=:currency",
      signal: "signal/tpsl-signals/?limit=:limit",
      stockAnalysis: "stock/stock-analysis/?limit=:limit",
      stockAnalysisFiltered:
        "stock/stock-analysis/?limit=:limit&stock__ticker=:ticker&stock__market=:market",
      stockPageAnalysisFiltered:
        "stock/stock-analysis/?limit=:limit&stock__ticker=:ticker&stock__market=:market&active=True&exclude_related_stocks=1",
      stockAnalysesFilteredByCategory:
        "stock/stock-analysis-categories/?stock__ticker=:ticker&stock__market=:market&active=True&exclude_related_stocks=1",
      stockPageSingleAnalyses:
        "stock/stock-analysis/?stock__ticker=:ticker&stock__market=:market&category_id=:category_id&active=True",
      longTermSignal: "signal/long-term-signal/",
      reportDates: "stock/stock_report_dates/:stockId/",
      dividends: "financial_report/dividends/:stockId/",
      stock: "stock/get/:marketId/:stockId/"
    },
    financialSituation: {
      stock: "financial_situation/stock/:stockId"
    },
    signal: {
      get: "signal/signal/:signalId/"
    },
    stockAnalysis: {
      get: "stock/stock-analysis/:stockAnalysisId/"
    },
    subscriptionStatus: {
      get: "notifications/subscription_status/:stockId/",
      setDefault: "notifications/subscription_status/:category/:stockId/",
      setSpecific:
        "notifications/subscription_status/:category/:channel/:stockId/"
    },
    globalSubscriptionStatus: {
      get: "notifications/subscription_status/",
      setDefault: "notifications/subscription_status/:category/",
      setSpecific: "notifications/subscription_status/:category/:channel/"
    },
    notices: {
      get: "notices/stock/:stockId",
      save: "notices/:noticeId"
    },
    stockSeoData: {
      getSectionList: "seo/:marketId/",
      getSectionSeoData: "seo/:marketId/:stockId/:section",
      getMarketList: "seo/markets-languages"
    },
    priceAlerts: {
      add: "price_alerts/:stockId",
      get: "price_alerts/:stockId",
      delete: "price_alerts/:stockId/:alertId"
    },
    payment: {
      details: "payment/details/",
      transactionHistory: "payment/history/",
      authorize: "payment/authorize/",
      release: "payment/release/",
      userOffer: "payment/user_offer/",
      offers: "payment/offers/?offer_id=:offerId"
    },
    payu: {
      create: "payu/create/"
    },
    bluemedia: {
      pretransaction: "bluemedia/pretransaction"
    },
    tradingView: {
      imageChart: "squaber/static_chart/:market/:ticker/:width?t=:time"
    },
    portfolio: {
      singlePortfolio: "portfolio/:id",
      chart:
        "portfolio/:portfolioId/chart/?count=:count&start=:start&cum_equity=1",
      rate_of_return_chart:
        "portfolio/:portfolioId/chart/?count=:count&start=:start",
      transactionHistory: "portfolio/:portfolioId/transaction/?limit=:limit",
      deleteTransaction: "portfolio/:portfolioId/transaction/:transactionId/",
      transaction: "portfolio/:portfolioId/transaction/",
      list: "portfolio/",
      aggregated:
        "portfolio/:portfolioId/aggregate/?market=:market&ticker=:ticker",
      cashOperations:
        "portfolio/:portfolioId/transaction/?model=cash_balance_change",
      transactionHistoryUniversalTable:
        "portfolio/:id/transaction/?model=:model&:options",
      stock: "portfolio/stock/:stockId"
    },
    marketing: {
      bar: "marketing/bar/",
      messages: "marketing/messages/",
      skipMarketingMessage: "marketing/messages/:messageId/skip",
      acceptMarketingMessage: "marketing/messages/:messageId/accept"
    },
    faq: {
      questions: "faq/questions/"
    },
    config: {
      termsAndConditions: "config/termsAndConditions",
      cookiesPolicy: "config/cookiesPolicy",
      homeLandingPage: "config/landingPageUrl?string_val=1",
      offerUrl: "config/offerUrl?string_val=1",
      affiliationTermsAndConditions: "config/affiliationTermsAndConditions",
      affiliationTermsAndConditionsPdf:
        "config/affiliationTermsAndConditionsPdf?string_val=1",
      upcomingFeatures: "config/upcomingFeatures",
      changesHistory: "config/changesHistory"
    },
    contact: {
      send: "squaber/contact/"
    },
    csvData: {
      config: "csv_data/:id/",
      configWithSettings: "csv_data/:id/?:settings",
      tab: "csv_data/:id/tab/:tabId?limit=:limit",
      tabWithOptions: "csv_data/:id/tab/:tabId?:options",
      financialReport: {
        insiderTransactions: {
          config: "financial_report/insider-transactions-table-config",
          configWithSettings:
            "financial_report/insider-transactions-table-config?:settings",
          tab: "financial_report/insider-transactions-table?limit=:limit",
          tabWithOptions: "financial_report/insider-transactions-table?:options"
        }
      }
    },
    chartTemplates: {
      list: "squaber/chart-template/",
      singleTemplate: "squaber/chart-template/:id/",
      remove: "squaber/chart-template/:id"
    },
    chartAnalyses: {
      list: "squaber/stock/:stockId/chart-analysis/",
      update: "squaber/stock/:stockId/chart-analysis/:analysisId",
      remove: "squaber/stock/:stockId/chart-analysis/:analysisId"
    },
    tags: {
      tag: "stock/tags",
      tagsWithStocksInfo: "stock/tags?return_stocks=true",
      stockTags: "stock/tags?stock_id=:stockId&return_admin_tags=true",
      allTags: "stock/tags?return_stocks=true",
      allTagsWithLimit: "stock/tags?return_stocks=true&limit=:limit",
      allTagsWithQuery:
        "stock/tags?query=:query&return_admin_tags=true&return_stocks=true&limit=:limit",
      userTagsWithQuery: "stock/tags?query=:query&&limit=:limit",
      singleTag: "stock/tags/:id",
      tagToStockConnection: "stock/tags/:tagId/:stockId",
      allAvailableTags: "stock/tags?return_stocks=true&return_admin_tags=true"
    },
    partnership: {
      data: "partnership/?limit=:limit&offset=:offset"
    },
    affiliateProgram: {
      details: "program/",
      history: "program/event/history/",
      relation: "program/relation/",
      acceptRegulations: "program/agreement/"
    },
    browserView: {
      interestingStocks: "browser-view/interesting-stocks/?markets[]=:markets",
      interestingStocksNoMarkets: "browser-view/interesting-stocks/"
    },
    stripe: {
      createOrder: "stripe/create-order/",
      createCustomerPortalSession: "stripe/create-customer-portal-session/"
    },
    revenueCat: {
      relation: "revenue-cat/relation/"
    },
    strategies: {
      manual: "strategies/manual/",
      transaction: "strategies/transaction/"
    },
    sm: {
      event: "xYbNzHmIjK/sGpLwJqKtR"
    },
    staticPages: {
      list: "static-pages/",
      bySlug: "static-pages/by-slug/:slug",
      byId: "static-pages/:id"
    },
    staticSeoData: {
      retrieve: "seo/pages/retrieve?slug=:slug"
    }
  },
  fe: {
    home: "",
    homeWithLocale: ":locale/",
    search: ":locale/search",
    login: ":locale/login",
    loginWithRedirect: ":locale/login?redirectTo=:redirectUrl",
    register: ":locale/register",
    registerWithRedirect: ":locale/register?redirectTo=:redirectUrl",
    resetPassword: ":locale/reset-password",
    stockPage: ":locale/:marketId/:stockId",
    stockPageSection: ":locale/:marketId/:stockId/:section",
    stockPageWithScrollToSection:
      ":locale/:marketId/:stockId?scroll_to_stock_page_section=:section",
    tradingViewWidget:
      ":locale/fullscreenchart/:marketId/:stockId?auth_token=:authToken&tv_full_screen=true&userAccessType=:userAccessType",
    tradingViewWidgetWithNoAuthToken:
      ":locale/fullscreenchart/:marketId/:stockId?tv_full_screen=true&userAccessType=:userAccessType",
    fullScreenChart:
      ":locale/fullscreenchart/:marketId/:stockId?tv_full_screen=true",
    fullScreenChartWithReturnTo:
      ":locale/fullscreenchart/:marketId/:stockId?tv_full_screen=true&return_to=:returnTo",
    lightWeightChartPage: ":locale/lightweightchart/:stockId",
    paymentDetails: ":locale/payment-details",
    fullscreenIntercomPage:
      ":locale/fullscreen-intercom?user=:userDataQueryString",
    fullscreenIntercomPageAnonymous: ":locale/fullscreen-intercom",
    settingsPage: ":locale/settings",
    settingsPageCategory: ":locale/settings/:settingsCategory",
    settingsPageCategoryAfterPayment:
      ":locale/settings/:settingsCategory?after_payment=true",
    paymentForm: ":locale/payment-details/:offerId",
    paymentFormWithAutoLogin:
      ":locale/payment-details/:offerId?auth_token_for_login=:authToken&will_redirect_to_native_after_payment=true",
    portfolio: ":locale/portfolios/:id",
    portfoliosPage: ":locale/portfolios",
    interestingStocks: ":locale/analysis?category_id=4",
    portfoliosPageWithoutRedirect: ":locale/portfolios?no_redirect=true",
    faqQuestion: ":locale/faq/:questionId",
    myStocksAfterLogin: ":locale/my-stocks?after_login=true",
    tagsSettings: ":locale/settings/tags",
    tagsSettingsAddTag: ":locale/settings/tags/new",
    tagSettings: ":locale/settings/tags/tag/:id",
    desktopPage: ":locale/pulpit/",
    desktopPageWithTagId: ":locale/pulpit/:tagId",
    affiliateLink: ":locale/affiliate?value=:token",
    analysisList: ":locale/analysis",
    singleAnalysisPage: ":locale/analysis/:id",
    manualStrategyPage: ":locale/manual-strategies/:id",
    turtleStrategySummary: ":locale/trend-following-strategy-signal-list",
    privacyPolicy: ":locale/regulations/privacy-policy"
  },
  nativeSceneUrl: {
    home: ":locale/followed-stocks",
    followedStocksView: ":locale/followed-stocks",
    notifications: ":locale/notifications",
    userSettings: ":locale/settings/personal",
    settingsCategory: ":locale/settings/:settingsCategory",
    profile: ":locale/profile",
    profileHome: ":locale/profile",
    profileLogin: ":locale/login",
    profileRegister: ":locale/register",
    portfolios: ":locale/portfolios",
    portfolio: ":locale/portfolios/:id",
    menuHome: ":locale/menu",
    stocksTable: ":locale/stocks-table",
    interestingStocks: ":locale/interesting-stocks",
    analysis: ":locale/analysis",
    turtleStrategySummary: ":locale/trend-following-strategy-signal-list",
    longTermSignals: ":locale/long-term-signals",
    stockPage: ":locale/stock/:marketId/:stockId",
    fullscreenIntercom: ":locale/fullscreen-intercom",
    searchPage: ":locale/search",
    resetPassword: ":locale/reset-password"
  },
  urlToNativeSceneUrl: {
    "/:locale/followed-stocks": "followedStocksView",
    "/:locale/notifications": "notifications",
    "/:locale/settings/personal": "userSettings",
    "/:locale/settings/:settingsCategory":
      "userSettings?defaultCategory=:settingsCategory",
    "/:locale/settings": "userSettings",
    "/:locale/profile": "profileHome",
    "/:locale/login": "profileLogin",
    "/:locale/register": "profileRegister",
    "/:locale/portfolios": "portfolios",
    "/:locale/portfolios/:id": "portfolio?id=:id",
    "/:locale/payment-details/:id": "userSettings?defaultCategory=payments",
    "/:locale/menu": "menuHome",
    "/:locale/stocks-table": "stocksTable",
    "/:locale/interesting-stocks": "interestingStocks",
    "/:locale/analysis": "analysis",
    "/:locale/analysis/:id": "__showModal?modal=analysis&analysisId=:id",
    "/:locale/trend-following-strategy-signal-list": "turtleStrategySummary",
    "/:locale/long-term-signals": "longTermSignals",
    "/:locale/:marketId/:stockId":
      "stockPage?match[params][marketId]=:marketId&match[params][stockId]=:stockId",
    "/:locale/search": "searchPage",
    "/:locale/reset-password": "resetPassword"
  },
  fbTracker: {
    trackEvent: `https://graph.facebook.com/:appId/activities
      ?event=:event
      &custom_events=:customEvents
      &application_tracking_enabled=:applicationTrackingEnabled
      &advertiser_tracking_enabled=:advertiserTrackingEnabled
      &advertiser_id=:advertiserId
      &:clientToken`
  }
};
